import produce from 'immer';
import types from './types';

const initialState = {
  categories: [],
  operatorsGroups: [],
  integrationStatus: '',
  statuses: [],
};

export default function topdesk(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.VALIDATE_TOPDESK_SETTINGS_SUCCESS:
        draft.categories = action.payload.categories;
        draft.operatorsGroups = action.payload.operatorsGroups;
        draft.integrationStatus = action.payload.status;
        break;

      case types.GET_TOPDESK_STATUSES_SUCCESS:
        draft.statuses = action.payload.statusList;
        break;

      default:
    }
  });
}
