import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import api from '../../../services/api';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import { updateBotLastUpdate } from '../bots/actions';
import * as actions from './actions';
import types from './types';

export function* fetchConnectors({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId } = payload;

    const response = yield call(api.get, `/v3/bots/${botId}/connectors`);

    yield put(
      actions.fetchConnectorsSuccess({
        connectors: response.data?.connectors || [],
      })
    );
  } catch (err) {
    console.log(err);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchSubflowConnectors({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId, subflowId } = payload;

    const response = yield call(
      api.get,
      `/v3/bots/${botId}/connectors-subflow/${subflowId}`
    );

    yield put(
      actions.fetchSubflowConnectorsSuccess({
        connectors: response.data,
      })
    );
  } catch (err) {
    console.log(err);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchAllConnectors({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId } = payload;

    const response = yield call(api.get, `/v3/bots/${botId}/all-connectors`);

    yield put(
      actions.fetchAllConnectorsSuccess({
        allConnectors: response.data,
      })
    );
  } catch (err) {
    console.log(err);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteConnectors({ payload }) {
  try {
    const { botId, connectorIds, isTemp } = payload;
    if (!isTemp) {
      yield call(api.delete, `/v3/bots/${botId}/connectors`, {
        data: { connectorIds },
      });
      yield put(updateBotLastUpdate({ botId }));
    }

    const connectors = yield select((state) => state.connectors.connectors);
    const newConnectors = [...connectors];

    for (let index = 0; index < connectorIds.length; index++) {
      const itemIndex = newConnectors.findIndex(
        (connector) => connector._id === connectorIds[index]
      );
      if (itemIndex !== -1) {
        newConnectors.splice(itemIndex, 1);
      }
    }
    yield put(actions.deleteConnectorsSuccess(newConnectors));
  } catch (err) {
    console.log(err);
  }
}

export function* createConnector({ payload }) {
  try {
    if (payload?.isTemp) {
      return yield put(
        actions.createConnectorSuccess({
          connector: { ...payload, isTemp: true },
        })
      );
    }

    const { data } = yield call(
      api.post,
      `/v3/bots/${payload.botId}/connectors`,
      payload
    );
    yield put(actions.createConnectorSuccess({ connector: data }));
    yield put(updateBotLastUpdate({ botId: payload.botId }));
  } catch (err) {
    console.log(err);
  }
}

export function* updateConnectors({ payload }) {
  try {
    const { botId } = payload[0];
    yield call(api.put, `/v3/bots/${botId}/connectors`, payload);
    yield put(updateBotLastUpdate({ botId }));
  } catch (err) {
    console.log(err);
  }
}

export default all([
  takeLatest(types.FETCH_CONNECTORS, fetchConnectors),
  takeLatest(types.FETCH_SUBFLOW_CONNECTORS, fetchSubflowConnectors),
  takeLatest(types.FETCH_ALL_CONNECTORS, fetchAllConnectors),
  takeLatest(types.DELETE_CONNECTORS, deleteConnectors),
  takeLatest(types.CREATE_CONNECTOR, createConnector),
  takeLatest(types.UPDATE_CONNECTORS, updateConnectors),
]);
