import types from './types';

export const listVariables = (payload) => ({
  type: types.LIST_VARIABLES,
  payload,
});
export const listVariablesSuccess = (payload) => ({
  type: types.LIST_VARIABLES_SUCCESS,
  payload,
});

export const createVariable = (payload, callback) => ({
  type: types.CREATE_VARIABLE,
  payload: { ...payload, callback },
});
export const createVariableSuccess = (payload) => ({
  type: types.CREATE_VARIABLE_SUCCESS,
  payload,
});

export const editVariable = (payload, callback) => ({
  type: types.EDIT_VARIABLE,
  payload: { ...payload, callback },
});
export const editVariableSuccess = (variable) => ({
  type: types.EDIT_VARIABLE_SUCCESS,
  payload: { variable },
});

export const removeVariable = ({ id }) => ({
  type: types.REMOVE_VARIABLE,
  payload: { id },
});
export const removeVariableSuccess = ({ id }) => ({
  type: types.REMOVE_VARIABLE_SUCCESS,
  payload: { id },
});
