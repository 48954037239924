import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';

import * as S from './styles';
import ModalFooter from './ModalFooter';
import ModalContent from './ModalContent';
import ModalHeader from './ModalHeader';
import ModalTitle from './ModalTitle';
import ModalButtonClose from './ModalButtonClose';

const Modal = ({
  isOpen,
  style,
  maxWidth,
  maxHeight,
  className,
  title,
  children,
  onClose,
  contentStyle,
  isFixed,
  showHeader,
  zIndex,
}) => {
  const modalRef = useRef();

  const handleKeyUp = useCallback(
    (e) => {
      const keys = {
        // ESC Key
        27: () => {
          e.preventDefault();
          if (!isFixed) {
            onClose?.();
          }
          window.removeEventListener('keyup', handleKeyUp, false);
        },
      };

      if (keys[e.keyCode] && isOpen) {
        keys[e.keyCode]();
      }
    },
    [onClose, isOpen]
  );

  const handleOutsideClick = useCallback(
    (e) => {
      if (modalRef.current.parentNode === e.target) {
        if (!isFixed) {
          onClose?.();
        }
        document.removeEventListener('click', handleOutsideClick, false);
      }
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false);
    document.addEventListener('click', handleOutsideClick, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, [handleKeyUp, handleOutsideClick]);

  return (
    <S.FullScreen
      isFixed={isFixed ? 1 : 0}
      isOpen={isOpen ? 1 : 0}
      zIndex={zIndex}
    >
      <S.Modal
        ref={modalRef}
        style={style}
        maxWidth={maxWidth}
        className={className}
      >
        <S.ModalContainer
          className="styleOverlay"
          maxHeight={maxHeight}
          style={contentStyle}
        >
          {showHeader && (
            <S.ModalHeader>
              <S.ModalTitle>{title}</S.ModalTitle>
              <S.ModalButtonClose type="button" onClick={onClose}>
                <MdClose color="#5A5D68" size={16} />
              </S.ModalButtonClose>
            </S.ModalHeader>
          )}
          {children}
        </S.ModalContainer>
      </S.Modal>
    </S.FullScreen>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.func,
  ]),
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any),
  contentStyle: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  maxWidth: PropTypes.string,
  maxHeight: PropTypes.string,
  isFixed: PropTypes.bool,
  showHeader: PropTypes.bool,
  zIndex: PropTypes.number,
};

Modal.defaultProps = {
  title: '',
  style: {},
  contentStyle: {},
  className: '',
  maxWidth: '360px',
  maxHeight: 'max-content',
  isFixed: false,
  showHeader: true,
  onClose: () => {},
  zIndex: 9999,
};

export { ModalHeader, ModalTitle, ModalButtonClose, ModalContent, ModalFooter };
export default Modal;
