import types from './types';

export const validateTopdeskSettingsRequest = (payload) => ({
  type: types.VALIDATE_TOPDESK_SETTINGS_REQUEST,
  payload,
});

export const validateTopdeskSettingsSuccess = (payload) => ({
  type: types.VALIDATE_TOPDESK_SETTINGS_SUCCESS,
  payload,
});

export const getTopdeskStatusesRequest = (payload) => ({
  type: types.GET_TOPDESK_STATUSES_REQUEST,
  payload,
});

export const getTopdeskStatusesSuccess = (payload) => ({
  type: types.GET_TOPDESK_STATUSES_SUCCESS,
  payload,
});
