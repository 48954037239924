import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SideMenu from './SideMenu';
import * as S from './styles';
import FirstStepsModal from '~/pages/Home/components/FirstStepsModal';
import ModalCreateBot from '~/pages/Bots/components/ModalCreateBot';

const DefaultLayout = ({ children }) => {
  const [isOpenedSideMenu, setIsOpenedSideMenu] = useState(true);
  const [, setModalIsOpen] = useState(false);

  return (
    <S.Container>
      <SideMenu
        isOpened={isOpenedSideMenu}
        onToggle={() => setIsOpenedSideMenu(!isOpenedSideMenu)}
      />
      <S.Children className="using-sidebar">{children}</S.Children>
      <FirstStepsModal setIsOpen={setModalIsOpen} />
      <ModalCreateBot />
    </S.Container>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default DefaultLayout;
