import { takeLatest, put, all, call, select } from 'redux-saga/effects';

import api from '~/services/api';
import i18n from '~/translations/i18n';

import { toast } from '~/components/CustomToast';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';

import * as actions from './actions';
import types from './types';

export function* listVariables(action) {
  const { payload } = action;
  try {
    const { data } = yield call(api.get, `/v3/bots/variable/${payload.botId}`);

    yield put(actions.listVariablesSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_list_variables'));
  }
}

export function* createVariable({ payload }) {
  const { callback } = payload;
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.post, '/v3/bots/variable', payload.values);
    const variables = yield select((state) => state.variables);

    const newVariables = {
      bot: [...variables.bot, data],
      global: variables.global,
    };

    callback?.();
    yield put(actions.createVariableSuccess(newVariables));
    toast.success(i18n.t('success.success_create_variable'));
  } catch (error) {
    if (
      error.response?.data?.statusCode === 409 &&
      error.response?.data?.message === 'variable already exists'
    ) {
      toast.error(i18n.t('error.oops'), i18n.t('error.error_variable_exists'));
    } else {
      const message =
        error.response?.data?.message || i18n.t('error.error_create_variable');
      toast.error(i18n.t('error.oops'), message);
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* editVariable({ payload }) {
  const { callback } = payload;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.put,
      `/v3/bots/variable/${payload._id}`,
      payload
    );

    callback?.();
    yield put(actions.editVariableSuccess(data));
    toast.success(i18n.t('success.success_update_variable'));
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_update_variable'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* removeVariable(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());

    yield call(api.delete, `/v3/bots/variable/${payload.id}`);

    yield put(actions.removeVariableSuccess({ id: payload.id }));
    toast.success(i18n.t('success.success_delete_variable'));
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_delete_variable'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.LIST_VARIABLES, listVariables),
  takeLatest(types.CREATE_VARIABLE, createVariable),
  takeLatest(types.EDIT_VARIABLE, editVariable),
  takeLatest(types.REMOVE_VARIABLE, removeVariable),
]);
