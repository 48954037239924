import axios from 'axios';
import { toast } from '../components/CustomToast';
import i18n from '../translations/i18n';

const url = process.env.REACT_APP_API_URL || 'http://localhost:3333';

const api = axios.create({
  baseURL: `${url}/api` || 'http://localhost:3333',
});

const UNAUTHORIZED_STATUS = 401;
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === UNAUTHORIZED_STATUS) {
      localStorage.removeItem('persist:@boteria-frontend');
      toast.error(i18n.t('error.error'), i18n.t('error.error_token_expired'));
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export default api;
