const Types = {
  VALIDATE_TOPDESK_SETTINGS_REQUEST:
    'topdesk/VALIDATE_TOPDESK_SETTINGS_REQUEST',
  VALIDATE_TOPDESK_SETTINGS_SUCCESS:
    'topdesk/VALIDATE_TOPDESK_SETTINGS_SUCCESS',
  GET_TOPDESK_STATUSES_REQUEST: 'topdesk/GET_TOPDESK_STATUSES_REQUEST',
  GET_TOPDESK_STATUSES_SUCCESS: 'topdesk/GET_TOPDESK_STATUSES_SUCCESS',
};

export default Types;
