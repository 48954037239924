import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  bot: [],
  global: [],
  vtex: [],
};

export default function variables(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.CREATE_VARIABLE_SUCCESS: {
        draft.bot = action.payload.bot;
        draft.global = action.payload.global;
        draft.vtex = action.payload.vtex;
        break;
      }

      case types.LIST_VARIABLES_SUCCESS: {
        draft.bot = action.payload.bot;
        draft.global = action.payload.global;
        draft.vtex = action.payload.vtex;
        draft.human = action.payload.human;
        break;
      }

      case types.EDIT_VARIABLE_SUCCESS: {
        const updatedVariable = action.payload.variable;
        draft.bot = state.bot.map((variable) =>
          variable._id === updatedVariable._id ? updatedVariable : variable
        );
        break;
      }

      case types.REMOVE_VARIABLE_SUCCESS: {
        const updatedBotVariables = state.bot.filter(
          (variable) => variable._id !== action.payload.id
        );
        draft.bot = updatedBotVariables;
        break;
      }

      default:
        return state;
    }
  });
}
