import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  ia: {},
  intents: [],
  entity: [],
  entities: [],
  samples: [],
  samplesAll: [],
  intentSamples: [],
  curation: { messages: [] },
  conditions: [],
  aprimoramentos: [],
  platform: '',
  iaInfo: {},
  provider: {},
  filterCuration: {},
  validateToken: null,
};

export default function intelligence(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.IA_LIST_PROVIDER_SUCCESS: {
        draft.provider = action.payload.data;
        break;
      }
      case types.IA_UPDATE_APPLICATION_SUCCESS: {
        draft.provider = action.payload;
        break;
      }
      case types.IA_TEST_VALIDATE_TOKEN_SUCCESS: {
        draft.validateToken = action.payload.data;
        break;
      }
      case types.RESET_VALIDATE_TOKEN: {
        draft.validateToken = action.payload.token;
        break;
      }
      case types.IA_LIST_INTENTS_SUCCESS: {
        draft.intents = action.payload.data.intents;
        break;
      }
      case types.IA_LIST_SAMPLES_SUCCESS: {
        draft.samples = action.payload.data;
        break;
      }
      case types.IA_LIST_ALL_SAMPLES_SUCCESS: {
        draft.samplesAll = action.payload.data;
        break;
      }

      case types.IA_LIST_SAMPLES_INTENT_SUCCESS: {
        draft.intentSamples = action.payload.data.data;
        draft.intents = draft.intents.map((intent) => {
          if (intent.name === action.payload.data.intent) {
            return {
              ...intent,
              qtdSamples: action.payload.data.data.length,
            };
          }
          return intent;
        });
        break;
      }

      case types.IA_PLATFORM_INFO_SUCCESS: {
        draft.iaInfo = action.payload.token;
        break;
      }

      case types.IA_LIST_CONDITIONS_SUCCESS: {
        draft.conditions = action.payload;
        break;
      }
      case types.IA_CREATE_CONDITIONS_SUCCESS: {
        draft.conditions = [...state.conditions, action.payload];
        break;
      }
      case types.IA_DELETE_CONDITIONS_SUCCESS: {
        draft.conditions = state.conditions.filter(
          (condition) => condition._id !== action.payload
        );
        break;
      }
      case types.IA_UPDATE_CONDITIONS_SUCCESS: {
        draft.conditions = state.conditions.map((condition) =>
          condition._id === action.payload._id
            ? { ...condition, ...action.payload }
            : condition
        );
        break;
      }

      case types.IA_PLATFORM_SUCCESS:
        draft.platform = action.payload.data;
        break;
      case types.IA_CREATE_PROVIDER_SUCCESS:
        draft.ia = { ...state.ia, wit: action.payload };
        draft.platform = action.payload;
        break;

      case types.IA_LIST_ENTITIES_SUCCESS:
        draft.entities = action.payload;
        break;

      case types.IA_LIST_ENTITY_SUCCESS:
        draft.entity = action.payload;
        break;

      case types.IA_CREATE_ENTITIES_SUCCESS:
        draft.entities = action.payload;
        break;

      case types.IA_DELETE_ENTITIES_SUCCESS:
        draft.entities = action.payload;
        break;

      case types.IA_DELETE_ENTITIES_OBJ_SUCCESS:
        draft.entities = action.payload;
        break;

      case types.IA_DELETE_ENTITIES_SYN_SUCCESS:
        draft.entities = action.payload;
        break;

      // curation
      case types.IA_CURATION_BOT_LIST_MESSAGES_SUCCESS: {
        draft.curation.messages = action.payload;

        break;
      }

      case types.IA_CURATION_BOT_IGNORE_MESSAGE_SUCCESS: {
        draft.curation.messages = state.curation.messages.filter(
          (message) => message._id !== action.payload.messageId
        );

        break;
      }

      case types.IA_CURATION_BOT_SET_APRIMORAMENTOS: {
        draft.aprimoramentos = [...state.aprimoramentos, action.payload];
        break;
      }
      case types.IA_CURATION_BOT_APRIMORAMENTOS_DELETE: {
        draft.aprimoramentos = state.aprimoramentos.filter(
          (aprimoramento) => aprimoramento !== action.payload
        );
        break;
      }
      case types.IA_CURATION_BOT_APRIMORAMENTOS_CLEAR: {
        draft.aprimoramentos = [];
        break;
      }

      case types.IA_CURATION_BOT_SAVE_APRIMORAMENTOS_SUCCESS: {
        draft.curation.messages = state.curation.messages.filter(
          (message) => message._id !== action.payload._id
        );
        break;
      }

      default:
        return state;
    }
  });
}
