import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  loading: false,
  items: [],
  itemsSummary: { data: null, error: false, loading: false },
  allItems: [],
  itemLastVersion: {},
  itemExample: {},
  subflowPath: [],
  returned: { id: undefined, isGroup: false },
  loadingCloneItemGroup: false,
  loadingCloneItems: false,
};

export default function items(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_ITEMS: {
        draft.loading = true;
        break;
      }

      case types.FETCH_ITEMS_SUCCESS: {
        draft.items = action.payload.items;
        draft.loading = false;
        draft.subflowPath = [];
        break;
      }

      case types.FETCH_SUBFLOW_ITEMS_SUCCESS: {
        draft.items = action.payload.items;
        draft.subflowPath = action.payload.subflowPath;
        draft.loading = false;
        break;
      }

      case types.FETCH_ALL_ITEMS_SUCCESS:
        draft.allItems = action.payload.allItems;
        break;

      case types.FETCH_ITEM_LAST_VERSION_SUCCESS:
        draft.itemLastVersion = action.payload.itemLastVersion;
        break;

      case types.CREATE_ITEM_SUCCESS:
        draft.items.push(action.payload);
        draft.allItems.push(action.payload);
        break;

      case types.IVR_CLONE_ITEM_GROUP: {
        draft.loadingCloneItemGroup = true;
        break;
      }
      case types.IVR_CLONE_ITEM_GROUP_SUCCESS: {
        draft.items.push(action.payload);
        draft.allItems.push(action.payload);
        draft.loadingCloneItemGroup = false;
        break;
      }
      case types.IVR_CLONE_ITEM_GROUP_FAILURE: {
        draft.loadingCloneItemGroup = false;
        break;
      }

      case types.IVR_CLONE_ITEMS: {
        draft.loadingCloneItems = true;
        break;
      }
      case types.IVR_CLONE_ITEMS_SUCCESS: {
        draft.items = [...(draft.items || []), ...action.payload];
        draft.allItems = [...(draft.allItems || []), ...action.payload];
        draft.loadingCloneItems = false;
        break;
      }
      case types.IVR_CLONE_ITEMS_FAILURE: {
        draft.loadingCloneItems = false;
        break;
      }

      case types.UPDATE_ITEM_SUCCESS:
        draft.items[action.payload.index] = action.payload.item;
        draft.allItems = draft.allItems.map((item) =>
          item._id === action.payload.item._id ? action.payload.item : item
        );
        break;

      case types.CREATE_ITEM_EXAMPLE_SUCCESS:
        draft.itemExample = action.payload.item;
        break;

      case types.UPDATE_ITEMS_SUCCESS:
        draft.items = action.payload;
        draft.allItems = draft.allItems.map(
          (item) => action.payload.find(({ _id }) => _id === item._id) || item
        );
        break;

      case types.DELETE_ITEM_SUCCESS: {
        const { items, allItems } = action.payload;
        draft.items = items;
        draft.allItems = allItems;

        break;
      }

      case types.RETURN_ITEM_SUCCESS: {
        draft.returned = action.payload.returned;
        break;
      }

      case types.FETCH_ITEMS_SUMMARY: {
        draft.itemsSummary.loading = true;
        draft.itemsSummary.error = false;
        break;
      }
      case types.FETCH_ITEMS_SUMMARY_SUCCESS: {
        draft.itemsSummary.loading = false;
        draft.itemsSummary.error = false;
        draft.itemsSummary.data = action.payload;
        break;
      }
      case types.FETCH_ITEMS_SUMMARY_FAILURE: {
        draft.itemsSummary.loading = false;
        draft.itemsSummary.error = true;
        draft.itemsSummary.data = null;
        break;
      }

      default:
        return state;
    }
  });
}
